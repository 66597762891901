const styleGuideAttributes = [
  'data-style-guide-shadow',
  'data-style-guide-border',
  'data-style-guide-button',
  'data-style-guide-headline',
  'data-style-guide-subheadline',
  'data-style-guide-content',
  'data-style-guide-corner',
]
export class CF2Component {
  id: string
  subscribers: Record<string, (() => void)[]>
  params: Record<string, any>
  runtimeSelectors: [string, Record<string, any>][]
  mutationOberver: MutationObserver

  constructor(protected element: CF2Element) {
    this.subscribers = {}
    this.id = Array.from(this.element.classList).find((c) => c.startsWith('id'))
    for (const propertyName of Object.getOwnPropertyNames(this.constructor.prototype)) {
      if (typeof this.constructor.prototype[propertyName] === 'function') {
        this.subscribers[propertyName] = []
      }
    }

    for (const dataName in this.element.dataset) {
      if (!dataName.startsWith('param')) {
        this[dataName] = this.element.dataset[dataName]
      }
    }

    const id = this.element.getAttribute('data-state-node-script-id')
    const stateNode = id && (document.getElementById(id) as HTMLElement)
    this.runtimeSelectors = []
    if (id && stateNode) {
      const data = JSON.parse(stateNode.textContent)
      this.runtimeSelectors = data?.runtimeSelectors ?? []
      delete data.runtimeSelectors
      Object.assign(this, data)

      if (this.runtimeSelectors.length > 0) {
        this.mutationOberver = new MutationObserver((mut) => this.onMutation(mut))
        this.mutationOberver.observe(this.element, {
          attributeOldValue: true,
          attributeFilter: ['class'],
          subtree: true,
        })
      }
    }
  }

  onMutation(mutations: MutationRecord[]): void {
    for (const mutation of mutations) {
      const element = mutation.target as HTMLElement
      for (const styleGuideAttr of styleGuideAttributes) {
        $(`[${styleGuideAttr}]`, element).removeAttr(styleGuideAttr)
      }
    }

    this.updateRuntimeState()
  }

  updateRuntimeState(): void {
    for (const [selector, attrs] of this.runtimeSelectors) {
      const $selected = $(selector, this.element)
      for (const attr in attrs) {
        $selected.attr(attr, attrs[attr])
      }
    }
  }

  // eslint-disable-next-line
  mount(element?: CF2Element): void {}

  getComponent(name: string): CF2Component {
    return this.element.querySelector<CF2Element>(`[data-page-element="${name}"]`)?.cf2_instance
  }

  getComponents(name: string): CF2Component[] {
    return Array.from(this.element.querySelectorAll<CF2Element>(`[data-page-element="${name}"]`))?.map(
      (c) => c.cf2_instance
    )
  }

  getAllComponents(): Record<string, CF2Component> {
    const componentList: Record<string, CF2Component> = {}
    Array.from(this.element.querySelectorAll<CF2Element>('[data-page-element]'))?.forEach((c) => {
      const pageElement = c.getAttribute('data-page-element').replace('/', '')
      componentList[pageElement] = c.cf2_instance
    })
    return componentList
  }

  on(eventName: string, eventHandler: () => void): void {
    if (this.subscribers[eventName]) {
      this.subscribers[eventName].push(eventHandler)
    } else {
      console.warn(`Event ${eventName} not supported by ${this.constructor.name}`)
    }
  }
  // NOTE: Build components by firstly building inner elements, and then walking up tree.
  // As we need to move from the leaf nodes to parent nodes. It also accepts a list of old
  // components in which you can re-use components built from an old list.
  static hydrateTree(parentNode?: HTMLElement): void {
    const nodes = (parentNode ?? document).querySelectorAll<CF2Element>('[data-page-element]')
    nodes.forEach((node) => {
      const closestPageElement = $(node.parentNode).closest('[data-page-element]')[0]
      if (closestPageElement == parentNode || closestPageElement == null) {
        CF2Component.hydrateTree(node as CF2Element)

        const klassName = node.getAttribute('data-page-element').replace('/', '')
        const ComponentBuilder = window[klassName]
        if (ComponentBuilder) {
          node.cf2_instance = new ComponentBuilder(node)
          node.cf2_instance.mount()
          node.cf2_instance.updateRuntimeState()
        }
      }
    })
  }
}

interface CF2Element extends HTMLElement {
  cf2_instance: CF2Component
}

window.addEventListener('load', () => {
  CF2Component.hydrateTree()
})

globalThis.CF2Component = CF2Component

export class ForloopDrop {
  protected i = 0
  public length: number
  public constructor(length: number) {
    this.length = length
  }
  public next(): void {
    this.i++
  }
  get index0(): number {
    return this.i
  }
  get index(): number {
    return this.i + 1
  }
  get first(): boolean {
    return this.i === 0
  }
  get last(): boolean {
    return this.i === this.length - 1
  }
  get rindex(): number {
    return this.length - this.i
  }
  get rindex0(): number {
    return this.length - this.i - 1
  }
}

globalThis.CF2ForloopDrop = ForloopDrop
