function setAndGetIFrameId($el) {
  let frameId = null
  if ($el) {
    const iframe = $el.find('iframe')
    frameId = iframe.attr('name')
    iframe.attr('id', frameId)
  }
  return frameId
}

function CFhandleAllYouTubeBPVideos(videoList) {
  return function () {
    videoList.forEach(function ($blockPauseVideo) {
      let cfbpplayer
      const frameId = setAndGetIFrameId($blockPauseVideo)
      if (frameId) {
        cfbpplayer = new (window as any).YT.Player(frameId, {})
        CFhandleVideoLabels($blockPauseVideo, cfbpplayer, 'youtube', function () {
          cfbpplayer.seekTo(0, true)
          cfbpplayer.unMute()
        })
      }
    })
  }
}

function CFhandleWistiaBPVideo(video) {
  const cfbpplayer = video
  const $blockPauseVideo = $(video.container).closest('.elVideoWrapper')
  CFsetupSessionStarterBP(cfbpplayer, $blockPauseVideo)
  $blockPauseVideo.find('.start-session-wrapper').on('click', function () {
    cfbpplayer.play()
    cfbpplayer.unmute()
    $(this).remove()
  })
}

function CFcheckForVimeo(timeout, blockPauseVideos) {
  timeout = timeout >= 300 ? timeout : timeout + 50
  if (!(window as any).Vimeo) {
    window.setTimeout(CFcheckForVimeo, timeout, timeout, blockPauseVideos)
  } else {
    //Calls Vimeo player handler.
    CFhandleAllVimeoBPVideos(blockPauseVideos)
  }
}

function CFsetupMutedVideos(cfbpplayer, $blockPauseVideo) {
  let $iframeUnmute
  if (cfbpplayer) {
    $iframeUnmute = $blockPauseVideo.find('.iframeUnmute')
    $iframeUnmute.append(
      '<div class="unmute-player-wrapper"><span class="unmute-player-icon"><i class="fa fa-volume-up"/></span></div>'
    )
  }
}

//For each video type found process the inner videos by adding handlers.
function CFprocessBPVideos(videos) {
  Object.keys(videos).forEach(function (videoType) {
    if (videos[videoType].length == 0) return
    const videoList = videos[videoType]

    switch (videoType) {
      case 'youtube': {
        let bpFrameSrc
        //Pre processment for YouTube blocked paused videos
        videoList.forEach(function ($bpVideo) {
          bpFrameSrc = $bpVideo.find('iframe').attr('src')
          // make sure we have enablejsapi=1
          if (bpFrameSrc.indexOf('enablejsapi') < 0) {
            bpFrameSrc = bpFrameSrc.replace('autoplay=1', 'enablejsapi=1&autoplay=1')
            $bpVideo.find('iframe').attr('src', bpFrameSrc)
          }
        })

        //Adds YouTube player handler which will be called when it is ready.
        globalThis.onYouTubePlayerAPIReady = CFhandleAllYouTubeBPVideos(videoList)

        break
      }
      case 'wistia':
        //Pre-processment for Wistia blocked paused videos
        if (!document.getElementById('wistia-script')) {
          $('head').append('<script id="wistia-script" src="//fast.wistia.net/assets/external/E-v1.js"></script>')
        }
        globalThis._wq = globalThis._wq || []

        //Adds Wistia handler which will be called for EACH Wistia video created
        globalThis._wq.push({ id: '_all', onReady: CFhandleWistiaBPVideo })
        break

      case 'vimeo':
        //Pre-processment for Vimeo blocked paused videos
        if (!document.getElementById('vimeo-script')) {
          $('body').append('<script id="vimeo-script" src="https://player.vimeo.com/api/player.js"></script>')
        }

        // Loop until Vimeo is created, as it doesn't have a handler
        // compared to other videos types.
        CFcheckForVimeo(50, videoList)
        break
    }
  })
}

// Checks if any .iframeblocked video is found on the page, adds the videos found
// in a hashtable with the format: "VIDEOTYPE => ARRAY WITH VIDEOS" ,
// then process this hash table.
function CFstartBPVideos() {
  let $videoWrapper
  let videoType
  let foundVideos
  const $iframeBlockedVideos = $('.elVideo.iframeblocked, .elVideo.iframeunmuted')

  if ($iframeBlockedVideos.length) {
    foundVideos = { youtube: [], wistia: [], vimeo: [] }
    $iframeBlockedVideos.each(function () {
      const $this = $(this)
      videoType = $this.closest('.elVideoWrapper').attr('data-video-type')
      // Check if the video type exists in foundVideos hash
      if (!(videoType in foundVideos)) {
        // Remove any blockpause classes that would prevent clicking on video
        $this.removeClass('iframeblocked iframeunmuted')
        return true
      }

      $videoWrapper = $this.closest('.elVideoWrapper')
      foundVideos[videoType].push($videoWrapper)
    })
    CFprocessBPVideos(foundVideos)
  }
}

function CFhandleAllVimeoBPVideos(blockPauseVideos) {
  blockPauseVideos.forEach(function ($blockPauseVideo) {
    const frameId = setAndGetIFrameId($blockPauseVideo)
    if (frameId) {
      const cfbpplayer = new (window as any).Vimeo.Player(frameId)
      cfbpplayer.ready().then(function () {
        CFhandleVideoLabels($blockPauseVideo, cfbpplayer, 'vimeo', function () {
          cfbpplayer.setCurrentTime(0)
          cfbpplayer.setVolume(0.5)
        })
      })
    }
  })
}

function CFhandleVideoLabels($blockPauseVideo, cfbpplayer, videoType, unmuteCallback) {
  if ($blockPauseVideo.attr('data-' + videoType + '-block-pause') == 'true') {
    CFsetupSessionStarterBP(cfbpplayer, $blockPauseVideo)
    $blockPauseVideo.find('.start-session-wrapper').on('click', function () {
      unmuteCallback()
      $(this).remove()
    })
  } else {
    CFsetupMutedVideos(cfbpplayer, $blockPauseVideo)
    $blockPauseVideo.find('.unmute-player-wrapper').on('click', function () {
      unmuteCallback()
      $(this).closest('.iframeUnmute').remove()
    })
  }
}

function CFsetupSessionStarterBP(cfbpplayer, $blockPauseVideo) {
  if (cfbpplayer) {
    const sessionStarterText =
      $blockPauseVideo.attr('data-session-starter-text') ?? 'Click To Join Session In Progess...'
    // append the session starter
    $blockPauseVideo
      .find('.iframeBlocker')
      .append('<div class="start-session-wrapper"><span class="start-session">' + sessionStarterText + '</span></div>')
  }
}

function CFAddHTML5ExtraOptions() {
  const userVideosWrappers = Array.from(document.getElementsByClassName('elVideoWrapper'))
  userVideosWrappers.forEach((videoWrapper) => {
    const videoType = videoWrapper.getAttribute('data-video-type')
    const isHTML5 = videoType === 'html5'
    if (!isHTML5) return

    const video = videoWrapper.querySelector('video')
    video.setAttribute('controls', 'controls')
    video.setAttribute('onclick', 'this.paused ? this.play() : this.pause()')
  })
}

function CFsetupStickyVideo() {
  const userVideosWrappers = Array.from(document.getElementsByClassName('elVideoWrapper'))
  userVideosWrappers.forEach((videoWrapper) => {
    const videoType = videoWrapper.getAttribute('data-video-type')
    const isHTML5 = videoType === 'html5'
    const isVideoSticky = videoWrapper.getAttribute('data-is-video-sticky') === 'true'
    const isVideoStickyCloseable = videoWrapper.getAttribute('data-sticky-closeable') === 'true'
    let videoIframe
    if (isHTML5) {
      ;[videoIframe] = Array.from(videoWrapper.getElementsByClassName('vjs-default-skin'))
    } else {
      ;[videoIframe] = Array.from(videoWrapper.getElementsByTagName('iframe'))
    }

    if (isVideoSticky) {
      const isMobile = {
        Android: function () {
          return navigator.userAgent.match(/Android/i)
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i)
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i)
        },
        Opera: function () {
          return navigator.userAgent.match(/Opera Mini/i)
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i)
        },
        any: function () {
          return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()
        },
      }
      let closedPopupIcon = false

      if (isVideoStickyCloseable) {
        const [videoFluidWrapper] = Array.from(videoWrapper.getElementsByClassName('elVideo'))
        const closeVideoDiv = document.createElement('div')
        const closeVideoIcon = document.createElement('i')
        closeVideoDiv.classList.add('video-sticky-close-button-css')
        closeVideoIcon.className = 'fa fa-times'
        closeVideoDiv.appendChild(closeVideoIcon)
        closeVideoDiv.classList.add('closeIconDiv')
        videoFluidWrapper.appendChild(closeVideoDiv)

        const [closeIconDiv]: any = Array.from(videoWrapper.getElementsByClassName('closeIconDiv'))
        closeIconDiv.addEventListener('click', function (e) {
          e.preventDefault()
          closeIconDiv.style.display = 'none'
          videoIframe.classList.remove('video-sticky-css')
          closedPopupIcon = true
        })
      }

      // eslint-disable-next-line no-inner-declarations
      function CFrunStickyVideo() {
        const { bottom: videoWrapperBottom, top: videoWrapperTop } = videoWrapper.getBoundingClientRect()

        if (videoWrapperBottom <= 10) {
          videoIframe.classList.add('video-sticky-css')
          if (isVideoStickyCloseable) {
            const [closeIconDiv]: any = Array.from(videoWrapper.getElementsByClassName('closeIconDiv'))
            closeIconDiv.style.display = 'flex'
          }
        }
        if (videoWrapperTop >= 10) {
          videoIframe.classList.remove('video-sticky-css')
          if (isVideoStickyCloseable) {
            const [closeIconDiv]: any = Array.from(videoWrapper.getElementsByClassName('closeIconDiv'))
            closeIconDiv.style.display = 'none'
          }
        }
      }
      CFrunStickyVideo()
      window.addEventListener('scroll', function () {
        const windowWidth = window.innerWidth
        if (windowWidth > 640) {
          if (!isMobile.any()) {
            if (!closedPopupIcon) {
              CFrunStickyVideo()
            }
          }
        }
      })
    }
  })
}

function CFSetupVideoSizes() {
  // NOTE (Alessom): This is not the ideal way to solve the responsiveness
  // for the videos, but solving it only throught CSS would create some display
  // issues with the video height, which would not be ideal as well. So we decided
  // to go in this way for now.
  window.addEventListener('resize', function () {
    $('.elVideoWrapper').each(function () {
      const $elem = $(this)
      if ($elem.parent().hasClass('elPopupVideoContainer')) {
        return
      }
      const [wrapperWidth] = $elem.css('width').split('px')
      const $iframe = $elem.find('iframe')
      if (!$iframe) return
      const fluidClass = 'fluid-width-video-wrapper'

      const videoWidth = $($elem).attr('data-custom-width')
      const videoHeight = $($elem).attr('data-custom-height')
      const hasResized = $($elem).find(`.${fluidClass}`).length > 0
      if (videoWidth) {
        if (Number(wrapperWidth) < Number(videoWidth)) {
          if (!hasResized) {
            resizeVids($iframe, $elem)
          }
        } else {
          if (videoWidth !== 'auto') {
            if (Number(videoWidth) < Number(wrapperWidth)) {
              if (hasResized) {
                const fluidWrapper = $($elem).find(`.${fluidClass}`)
                fluidWrapper.css('padding-top', 0)
                fluidWrapper.css('border-radius', 'inherit')
                $iframe.css('width', videoWidth ?? 640)
                $iframe.css('height', videoHeight ?? 360)
                fluidWrapper.removeClass(fluidClass)
              }
            }
          }
        }
      }
    })
  })

  $('.elVideoWrapper').each(function () {
    const $elem = $(this)
    if ($elem.parent().hasClass('elPopupVideoContainer')) {
      fitVids($elem)
      return
    }
    const [wrapperWidth] = $elem.css('width').split('px')
    const videoType = $elem.attr('data-video-type')
    const $iframe = $elem.find('iframe')
    if (!$iframe) return
    const videoWidth = $($elem).attr('data-custom-width') ?? 640
    const videoHeight = $($elem).attr('data-custom-height') ?? 360
    const hasCustomSizes = !!$($elem).attr('data-custom-width') || !!$($elem).attr('data-custom-height')
    if (Number(wrapperWidth) > Number(videoWidth)) {
      if (hasCustomSizes) {
        if (videoType === 'custom') {
          if (videoHeight == 'auto' && videoWidth == 'auto') {
            fitVids($elem)
          }
        }

        if (Number(wrapperWidth) < Number(videoWidth)) {
          resizeVids($iframe, $elem)
        }
      } else {
        fitVids($elem)
      }
    } else {
      fitVids($elem)
    }
  })
}

function fitVids($elem: JQuery) {
  const customSelectors = [
    'iframe[src*="fast.wistia.net"]',
    'iframe[src*="embed.voomly.com"]',
    'iframe[data-src*="player.vimeo.com"]',
    'iframe[data-src*="youtube.com"]',
    'iframe[data-src*="youtube-nocookie.com"]',
    'iframe[data-src*="fast.wistia.net"]',
    'iframe[data-src*="embed.voomly.com"]',
    'iframe[data-src*="kickstarter.com"][data-src*="video.html"]',
  ]
  ;($elem as any).fitVids({ customSelector: customSelectors.join(',') })
}

function resizeVids($iframe: JQuery, $elem: JQuery) {
  $iframe.attr('width', 'auto')
  $iframe.attr('height', 'auto')
  fitVids($elem)
  $iframe.css('width', '100%')
  $iframe.css('height', '100%')
}

$(document).ready(function () {
  // load yt iframe js api if yt
  const url = window.location.href
  const queries = ($ as any).parseParams(url)
  // if no autoplay - stop all videos
  if (queries['noautoplay'] == 'true') {
    $('.elVideoWrapper').each(function () {
      const $elem = $(this)
      const videoType = $elem.attr('data-video-type')
      let src: string
      if (videoType == 'youtube') {
        src = $('.elVideo iframe', this).attr('src')
        src = src.replace('autoplay=1', 'autoplay=0')
        $('.elVideo iframe', this).attr('src', src)
      } else if (videoType == 'vimeo') {
        src = $('.elVideo iframe', this).attr('src')
        src = src.replace('autoplay=1', 'autoplay=0')
        $('.elVideo iframe', this).attr('src', src)
      } else if (videoType == 'wistia') {
        src = $('.elVideo iframe', this).attr('src')
        src = src.replace('autoPlay=1', 'autoPlay=0')
        $('.elVideo iframe', this).attr('src', src)
      }
    })
  }
  $('.iframeblocked').append("<div class='iframeBlocker'></div>")
  $('.iframeunmuted').append("<div class='iframeUnmute'></div>")

  if (document.body.innerHTML.search('data-video-type="youtube"') > 0 && !document.getElementById('youtube-script')) {
    const tag = document.createElement('script')
    tag.src = '//www.youtube.com/iframe_api'
    tag.id = 'youtube-script'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  }

  CFstartBPVideos()
  CFAddHTML5ExtraOptions()
  CFSetupVideoSizes()
  CFsetupStickyVideo()
})
