import { submitPage, restoreButtonState, checkValidInputs } from './submit'

// TODO: gotta review some types in this file

const scrollToForm = () => {
  if (!$('.elPAI').length) return
  $([document.documentElement, document.body]).animate({ scrollTop: $('.elPAI').offset().top - 50 }, 200)
}

globalThis.addEventListener('load', function () {
  $(document).on('change', '.elInput', function () {
    globalThis.processForm()
  })

  window.addEventListener('payments:token-ready', () => {
    globalThis.processForm()
    if (!checkValidInputs()) {
      restoreButtonState()
      scrollToForm()
      this.window.dispatchEvent(new CustomEvent('payments:submit-failed'))
      return
    }
    submitPage()
  })
})

export const rebillyProcessOrder = (): void => {
  const Rebilly = globalThis.Rebilly
  const extraData = { method: globalThis.paymentsSelectedPaymentMethod }
  const form = document.querySelector('#cfAR')
  window.dispatchEvent(new CustomEvent('payments:clear-errors'))
  globalThis.processForm()
  if (!checkValidInputs()) {
    restoreButtonState()
    scrollToForm()
    window.dispatchEvent(new CustomEvent('payments:submit-failed'))
    return
  }
  Rebilly.createToken(form, extraData)
    .then(function (result) {
      console.log('Framepay success', result)
      globalThis.parent.postMessage('success', '*')
      submitPage()
    })
    .catch(function (error) {
      console.log('Framepay error', error)
      globalThis.parent.postMessage('error', '*')
      ;(form.querySelector('input[data-rebilly="token"]') as any).value = ''
      window.dispatchEvent(
        new CustomEvent('payments:set-token-error', {
          detail: {
            error: {
              message: error,
            },
          },
        })
      )
      restoreButtonState()
      scrollToForm()
      window.dispatchEvent(new CustomEvent('payments:submit-failed'))
    })
}
globalThis.rebillyProcessOrder = rebillyProcessOrder
globalThis.scrollToForm = scrollToForm
