const unobserve = (element: Element, observer: IntersectionObserver) => {
  if (element.getAttribute('data-animation-once') === 'true') {
    observer.unobserve(element)
  }
}

const startAnimation = (element: Element) => {
  const delay = element.getAttribute('data-animation-delay')
  if (delay) {
    return setTimeout(() => {
      element.setAttribute('data-animation-default-values', 'false')
      element.setAttribute('data-animation-run', 'true')
    }, Number(delay))
  } else {
    element.setAttribute('data-animation-default-values', 'false')
    element.setAttribute('data-animation-run', 'true')
  }
}

$(document).ready(function () {
  const runAllAnimations = function () {
    const scrollElements = document.querySelectorAll('[data-animation-trigger="scroll"]')
    const loadElements = document.querySelectorAll('[data-animation-trigger="load"]')

    const config = {
      rootMargin: '50px 20px 75px 30px',
      threshold: [0, 0.25, 0.75, 1],
    }

    const onScrollTimeoutIdMap = {}

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        const classListId = entry.target.getAttribute('class') ?? ''
        if (entry.isIntersecting) {
          unobserve(entry.target, observer)
          if (entry.target.getAttribute('data-animation-run') !== 'true') {
            onScrollTimeoutIdMap[classListId] = startAnimation(entry.target)
          }
        } else {
          if (onScrollTimeoutIdMap[classListId]) clearTimeout(onScrollTimeoutIdMap[classListId])
          entry.target.setAttribute('data-animation-run', 'false')
          entry.target.setAttribute('data-animation-default-values', 'true')
        }
      })
    }, config)

    scrollElements.forEach((element) => {
      observer.observe(element)
    })

    loadElements.forEach((element) => {
      startAnimation(element)
    })
  }

  runAllAnimations()
})
